import { getQRCodes, getQRCodeCounts } from '@/helpers/firebase-functions'

const shortenURLIfRequired = long => {
  let short = long
  const MAX_LENGTH = 21
  if (long.length > MAX_LENGTH) {
    short = long.substr(0, MAX_LENGTH) + '...'
  }
  return short
}

export const getQRCodesWithCounts = async (ownerId, scenarioId, dates = null) => {
  let qrCodes = await getQRCodes({ ownerId, scenarioId })

  try {
    const { scannedCounts, interviewCounts } = dates ? await getQRCodeCounts(dates) : await getQRCodeCounts()
    qrCodes = qrCodes.map(code => {
      const { qrCodeId, url: long } = code
      const url = shortenURLIfRequired(long)
      return {
        ...code,
        url,
        count: scannedCounts[qrCodeId] ? scannedCounts[qrCodeId] : 0,
        interviews: interviewCounts[qrCodeId] ? interviewCounts[qrCodeId] : 0
      }
    })
  } catch (err) {
    throw err
  }

  return qrCodes
}
