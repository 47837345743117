<template>
  <v-dialog v-model="model_" width="500">
    <v-card>
      <v-card-title>QRコードCSVダウンロード</v-card-title>
      <v-card-text>
        <v-col>
          <v-row aligh="start" dense>
            <v-col cols="auto">
              <v-btn text @click="pickStart()">
                <v-icon left>mdi-calendar</v-icon>
                開始日({{ startDate }})
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <v-btn text @click="pickEnd()">
                <v-icon left>mdi-calendar</v-icon>
                終了日({{ endDate }})
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="closeDownload()">
          キャンセル
        </v-btn>
        <v-btn text @click="downloadStats()" :disabled="!isValidDates()">
          OK
        </v-btn>
      </v-card-actions>
      <v-dialog
        ref="startDialog"
        v-model="startDialog"
        :return-value.sync="startDate"
        persistent
        width="290px"
      >
        <v-date-picker v-model="startDate" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="cancelStart()">キャンセル</v-btn>
          <v-btn text color="primary" @click="$refs.startDialog.save(startDate)">OK</v-btn>
        </v-date-picker>
      </v-dialog>
      <v-dialog
        ref="endDialog"
        v-model="endDialog"
        :return-value.sync="endDate"
        persistent
        width="290px"
      >
        <v-date-picker v-model="endDate" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="cancelEnd()">キャンセル</v-btn>
          <v-btn text color="primary" @click="$refs.endDialog.save(endDate)">OK</v-btn>
        </v-date-picker>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'QRCodeStatsDownloadDialog',
  props: {
    model: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    startDialog: false,
    endDialog: false,
    startDate: '',
    endDate: ''
  }),
  computed: {
    model_: {
      get () {
        return this.model
      },
      set () {
        this.closeDownload()
      }
    }
  },
  methods: {
    pickStart () {
      this.startDialog = true
    },
    pickEnd () {
      this.endDialog = true
    },
    cancelStart () {
      this.startDate = ''
      this.startDialog = false
    },
    cancelEnd () {
      this.endDate = ''
      this.endDialog = false
    },
    isValidDates () {
      if (this.startDate === '' || this.endDate === '') {
        return false
      } else {
        return this.startDate <= this.endDate
      }
    },
    closeDownload () {
      this.$emit('closeDownload')
    },
    downloadStats () {
      this.$emit('downloadStats', { startDate: this.startDate, endDate: this.endDate })
      this.startDate = ''
      this.endDate = ''
      this.closeDownload()
    }
  }
}
</script>
