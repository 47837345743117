<template>
  <v-card>
    <v-card-title>
      {{$t('m.stats.title')}}
      <v-spacer/>
      <v-btn icon @click="downloadCSV">
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn icon @click="refresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      </v-card-title>
    <v-card-text>
      <v-data-table :headers="headers" :items="_qrCodes" sort-by="label" :loading="isLoading">
        <template v-slot:[`item.action`]="{ item }">
          <v-icon small @click.stop="updateQRCode(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="updateDialog" width="330">
      <v-card>
        <v-card-title>{{$t('m.stats.dialog.title')}}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col align-self="center">
              <v-text-field v-model="selected.label" :label="$t('m.stats.dialog.label')"></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col align-self="center">
              <v-text-field v-model="selected.qrCodeId" label="ID" readonly></v-text-field>
            </v-col>
          </v-row>-->
          <!-- <v-row>
            <v-col align-self="center">
              <v-text-field v-model="selected.url" label="URL" readonly></v-text-field>
            </v-col>
          </v-row>-->
          <!-- <v-row>
            <v-col align-self="center">
              <v-text-field v-model="selected.count" label="スキャン回数" readonly></v-text-field>
            </v-col>
          </v-row>-->
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="primary" @click="dialogCancel">{{$t('m.cancel')}}</v-btn>
          <v-btn text color="primary" @click="dialogSave">{{$t('m.save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <QRCodeStatsDownloadDialog :model="downloadDialog" @downloadStats="onDownloadStats" @closeDownload="onCloseDownload()"/>
  </v-card>
</template>

<script>
import { format } from 'date-fns'
import QRCodeStatsDownloadDialog from './QRCodeStatsDownloadDialog'

export default {
  name: 'qr-code-stats',
  components: {
    QRCodeStatsDownloadDialog
  },
  props: {
    qrCodes: {
      type: Array,
      required: false,
      default: () => { return [] }
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    _qrCodes () {
      return this.qrCodes.map(code => {
        const { archivedAt } = code
        return {
          ...code,
          _archivedAt: archivedAt ? format(new Date(archivedAt), 'yyyy/MM/dd HH:mm') : ''
        }
      })
    }
  },
  data: function () {
    return {
      headers: [
        {
          text: this.$i18n.t('m.stats.table.label'),
          align: 'start',
          sortable: true,
          value: 'label'
        },
        {
          text: 'URL',
          align: 'start',
          sortable: true,
          value: 'url'
        },
        {
          text: this.$i18n.t('m.stats.table.id'),
          align: 'start',
          sortable: true,
          value: 'qrCodeId'
        },
        {
          text: this.$i18n.t('m.stats.table.applicants'),
          align: 'end',
          sortable: true,
          value: 'interviews'
        },
        {
          text: this.$i18n.t('m.stats.table.scans'),
          align: 'end',
          sortable: true,
          value: 'count'
        },
        {
          text: this.$i18n.t('m.stats.table.deletedDateTime'),
          align: 'start',
          sortable: true,
          width: 10,
          value: '_archivedAt'
        },
        {
          text: this.$i18n.t('m.stats.table.action'),
          align: 'center',
          sortable: false,
          value: 'action'
        }
      ],
      selected: {},
      updateDialog: false,
      downloadDialog: false
    }
  },
  methods: {
    updateQRCode (item) {
      this.selected = { ...item }
      this.updateDialog = true
    },
    dialogCancel () {
      this.updateDialog = false
    },
    dialogSave () {
      delete this.selected._archivedAt
      this.$emit('update', this.selected)
      this.updateDialog = false
    },
    downloadCSV () {
      this.downloadDialog = true
    },
    refresh () {
      this.$emit('refresh')
    },
    onDownloadStats (dates) {
      this.$emit('downloadStats', dates)
    },
    onCloseDownload () {
      this.downloadDialog = false
    }
  }
}
</script>
