<template>
  <v-container fluid>
    <qr-code-stats
      :qrCodes="qrCodes"
      :isLoading="isLoading"
      @update="onUpdate"
      @refresh="onRefresh"
      @downloadStats="onDownloadStats"
    ></qr-code-stats>
    <a v-show="false" ref="doDownload" :href="csvUrl" :download="csvFilename">click</a>
    <div class="text-center"><v-progress-circular :indeterminate="indeterminate"/></div>
  </v-container>
</template>

<script>
import { updateQRCode } from '@/helpers/firebase-functions'
import qrCodeStats from '@/components/qrcode/QRCodeStats'
import { getQRCodesWithCounts } from '@/components/qrcode/Utils'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    qrCodeStats
  },
  data: () => ({
    timeZone: null,
    ownerId: '',
    scenarioId: '',
    qrCodes: [],
    isLoading: false,
    csvUrl: '',
    csvFilename: '',
    indeterminate: false
  }),
  methods: {
    async onUpdate (qrCode) {
      this.isLoading = true

      delete qrCode.createdAt
      await updateQRCode({ ownerId: this.ownerId, scenarioId: this.scenarioId, qrCode })
      try {
        this.qrCodes = await getQRCodesWithCounts(this.ownerId, this.scenarioId)
      } catch (err) {
        alert('QRコード統計情報を取得できませんでした。右上の「リロード」ボタンをクリックして再度試してください。')
        this.qrCodes = []
      }

      this.isLoading = false
    },
    async onRefresh () {
      this.isLoading = true
      try {
        this.qrCodes = await getQRCodesWithCounts(this.ownerId, this.scenarioId)
      } catch (err) {
        alert('QRコード統計情報を取得できませんでした。右上の「リロード」ボタンをクリックして再度試してください。')
        this.qrCodes = []
      }
      this.isLoading = false
    },
    async onDownloadStats (dates) {
      const { startDate, endDate } = dates

      try {
        this.indeterminate = true
        const qrCodes = await getQRCodesWithCounts(
          this.ownerId,
          this.scenarioId,
          { ...dates, timeZone: this.timeZone })

        const title = '\uFEFF' + 'ラベル,URL,ID,募集者数,スキャン回数' // prepend UTF BOM
        let content = title + '\n'
        for (const qrCode of qrCodes) {
          const { count, interviews, label, qrCodeId, url } = qrCode
          content += `${label},${url},${qrCodeId},${interviews},${count}` + '\n'
        }
        const blob = new Blob([content], { type: 'text/csv' })
        this.csvUrl = URL.createObjectURL(blob)
        this.csvFilename = `${startDate.replace(/-/g, '')}-${endDate.replace(/-/g, '')}.csv`
        this.$nextTick(() => {
          this.$refs.doDownload.click()
        })
      } catch (err) {
        alert('QRコード統計情報をダウンロードできませんでした。右上の「ダウンロード」ボタンをクリックして再度試してください。')
      } finally {
        this.indeterminate = false
      }
    }
  },
  async mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_QR_CODE_STATS)
    this.isLoading = true

    this.ownerId = this.$route.params.ownerId
    this.scenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
    try {
      this.qrCodes = await getQRCodesWithCounts(this.ownerId, this.scenarioId)
    } catch (err) {
      alert('QRコード統計情報を取得できませんでした。右上の「リロード」ボタンをクリックして再度試してください。')
      this.qrCodes = []
    }

    this.timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone

    this.isLoading = false
  }
}
</script>
